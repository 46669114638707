import PropTypes from 'prop-types';
const CaretUpIcon = ({
  fillColor,
  strokeColor,
  width,
  height,
  customClassNames
}) => {
  return (
    <svg
      className={`c-CaretUpIcon  ${customClassNames}`}
      width={width}
      height={height}
      fill={fillColor}
      viewBox="0 0 14 8">
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 7l6-6 6 6"></path>
    </svg>
  );
};

CaretUpIcon.propTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  customClassNames: PropTypes.string
};

CaretUpIcon.defaultProps = {
  fillColor: 'none',
  strokeColor: '#000',
  width: '14',
  height: '8',
  customClassNames: ''
};

export default CaretUpIcon;
