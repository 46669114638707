import IconByName from '@/components/common/IconsV2/IconByName';
import React from 'react';

const ProsConsSection = ({ title, cardInfoList }) => {
  return (
    <div className="px-24 py-64 md:px-[40px] md:py-[80px]">
      <h2
        className="text-heading-xl text-[#1b1b1b] font-semibold mb-32 md:text-center md:max-w-[615px] md:mx-auto md:mb-40 md:text-display-xs lg:text-display-sm"
        data-testid="homepage-pros-cons-title">
        {title}
      </h2>
      <div className="space-y-16 flex flex-col md:flex-row md:space-y-0 md:space-x-24 lg:max-w-[1120px] lg:mx-auto">
        {cardInfoList.map((cardInfo, index) => (
          <div
            key={index}
            style={{ backgroundColor: cardInfo.bgColor }}
            className="px-16 py-24 rounded-[28px] flex-1">
            <IconByName name={cardInfo.icon} />
            <p className="mt-24 mb-12 text-heading-md font-semibold text-[#1b1b1b]">
              {cardInfo.title}
            </p>
            <ul className="space-y-8">
              {cardInfo.list.map((item, index) => (
                <div key={index} className="flex items-center space-x-8">
                  <div className="flex-shrink-0">
                    <IconByName
                      name={cardInfo.listIcon}
                      fill={cardInfo.listIconColor}
                    />
                  </div>
                  <p className="text-body-md font-medium">{item}</p>
                </div>
              ))}
              <li></li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProsConsSection;
