export const modalFaqs = [
  {
    question: 'What is Nas Academy?',
    answer: [
      {
        text: "We are the world's best Creator school, and our online learning experience is changing lives every day. We are a force for good in today’s changing job marketing and media landscape.",
        isNewLine: true
      },
      {
        text: "At Nas Academy, you'll find a range of cohort-based on-demand and live courses that teach digital skills to empower people in the creator economy."
      }
    ]
  },
  {
    question: 'What is "cohort-based" learning?',
    answer: [
      {
        text: 'Our collaborative learning experience is so much more than “just watching video recordings." You\'ll join a cohort, an international group of classmates, that you can connect with in an exclusive community group.',
        isNewLine: true
      },
      {
        text: 'To learn more and read our alumni success stories,'
      },
      {
        text: 'Click Here',
        link: '/how-it-works',
        isNewLine: true
      }
    ]
  },
  {
    question: 'What software or hardware do I need before signing up?',
    answer: [
      {
        text: 'We do our best to make classes as accessible and budget-friendly as possible, especially for beginners. You’ll need to have:',
        isNewLine: true
      },
      {
        text: 'A good internet connection (you can even take the entire course on your mobile phone, using your 3G/4G network) The time to finish small projects after class (you need one hour for every hour of class)',
        isNewLine: true
      },
      {
        text: 'Depending on the course, you may be asked to purchase (or subscribe to a free trial) of the relevant software or have access to a digital camera (even a smartphone camera will work!).'
      }
    ]
  },
  {
    question: 'How do the live classes work?',
    answer: [
      {
        text: 'All of the live classes at Nas Academy are hosted on Zoom, and you’ll be taught by our trainers. It’s a fun, interactive experience, and we encourage you to turn on your video camera (Zoom software is free and easy to use).',
        isNewLine: true
      },
      {
        text: 'For more information, check out our'
      },
      {
        text: 'How It Works page',
        link: '/how-it-works',
        isNewLine: true
      }
    ]
  },
  {
    question: "When are the live classes? What if I can't attend them?",
    answer:
      "After signing up, you’ll get to choose the dates for your live classes. Select the dates that work best for your schedule. But if you can't attend the live classes, don't worry! You can still watch the recording whenever you\re free."
  },
  {
    question:
      'Will I have access to the video recording once the course is over?',
    answer:
      'Yes! You have LIFETIME access to everything in this course – including the resources and alumni community.'
  },
  {
    question: 'Will I get to meet the Creator of the course?',
    answer: 'Yes! There will be a live virtual meet-and-greet once a month'
  },
  {
    question: 'What if I want to change my dates?',
    answer: [
      {
        text: 'We’re happy to help you switch to new dates. Just send us an email. '
      },

      {
        text: 'Email Us',
        link: 'mailto:hello@nas.io',
        isNewLine: true
      }
    ]
  },
  {
    question:
      'Do I have to take any tests/exams or pass assignments in this course?',
    answer: [
      {
        text: 'No! Learning is supposed to be exciting. Our approach is “Learning by Doing.” You’ll have very small tasks to complete as well as a final project – which is all for you to practice everything that you’re learning.',
        isNewLine: true
      },
      {
        text: 'But you’ll never have to do it alone! Your trainers and your classmates will be there to help you. Whatever challenge you’re facing, you can discuss it with us.'
      }
    ]
  },
  {
    question:
      'Do I have to join the WhatsApp group to participate in a course?',
    answer: [
      {
        text: 'Our Creators are proud to host their Academies with Nas Academy because all students will become part of a global community. It’s what makes our learning experience the best! So many people have found mentors and friends through their WhatsApp group. We strongly encourage interaction in every class.',
        isNewLine: true
      },
      {
        text: 'To answer the question, if you cannot join your classmates on WhatsApp, you won’t experience the full effect of the course – but you are still welcome to sign up.'
      }
    ]
  },
  {
    question: 'Will I get a certificate as proof of my learning?',
    answer:
      'Yes! You will receive an online “Certificate of Completion.” We can’t wait for you to proudly share your achievements with your friends and family. Don’t forget to tag us on social media!'
  },
  {
    question: 'Is there an age limit for enrollment at Nas Academy?',
    answer:
      "All of our courses are recommended for 16 years and above. If you are below 18 years old, you'll have to get permission from your guardian."
  },
  {
    question:
      'My employer wants to pay for my course. Can I get a separate invoice?',
    answer: [
      {
        text: 'Yes! Many of our students are sponsored by their organization. Simply send us an email here with your request.'
      },
      {
        text: 'Email Us',
        link: 'mailto:hello@nas.io',
        isNewLine: true
      }
    ]
  },
  {
    question:
      'Where can I learn more about corporate courses for my company?',
    answer: [
      {
        text: 'We’d love to work with you. Please reach out here.'
      },
      {
        text: 'Email Us',
        link: 'mailto:hello@nas.io',
        isNewLine: true
      }
    ]
  },
  {
    question: 'What is the refund policy for Nas Academy courses?',
    answer: 'We offer a 100% money-back guarantee.'
  },
  {
    question:
      'What do I do if I have technical problems? Who can I contact for help?',
    answer: [
      {
        text: 'We’re here for you! Simply click the yellow chat widget on the bottom right-hand corner of this browser, or send us an email at'
      },
      {
        text: 'hello@nas.io',
        link: 'mailto:hello@nas.io',
        isNewLine: true
      }
    ]
  }
];
