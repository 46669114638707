import { useEffect, useState } from 'react';

import Button from '../Button';
import CaretDownIcon from '@/components/common/Icons/CaretDownIcon';
import CaretUpIcon from '@/components/common/Icons/CaretUpIcon';
import Link from 'next/link';
import Modal from '../Modal';
import Text from '../Text';
import { modalFaqs } from './constant';
import style from './FaqAccordian.module.scss';
import RoundedButton from '../RoundedButton';
import { useRouter } from 'next/router';

const FaqAccordian = (props) => {
  const {
    faqs,
    ctaProps,
    modalFaqs,
    modalFaqsTitle,
    showModalFaqsCta,
    modalFaqsCtaText,
    title = 'FAQ',
    withoutBg,
    expandFirstItem,
    hideTitle,
    customClassNames
  } = props;

  const router = useRouter();

  const [selectedFaqIdx, setSelectedFaqIdx] = useState('');
  const [isExtraInfoModalOpen, setIsExtraInfoModalOpen] = useState(false);

  function toggleFaq(faqKey) {
    if (selectedFaqIdx === faqKey) {
      setSelectedFaqIdx('');
    } else setSelectedFaqIdx(faqKey);
  }

  const renderCta = () => {
    if (!ctaProps) return null;
    const { label, link, isNPL } = ctaProps;

    if (isNPL) {
      return (
        <div className="flex justify-center mt-40">
          <RoundedButton
            displayType="primary"
            onClick={() => {
              router.push(ctaProps?.link);
            }}
            customClassNames="!h-[56px] !font-medium !text-button-lg w-full lg:w-auto"
            data-testid="homepage-community-categories-section-start-button">
            {ctaProps?.label}
          </RoundedButton>
        </div>
      );
    }

    return (
      <div className="mt-32 xl:max-w-[130px] xl:mx-auto xl:mt-56">
        <div className="w-full rounded-4 border-[1px] border-thick border-solid border-dark-33 cursor-pointer px-24 py-12 text-center font-medium">
          <Link href={link}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>{label}</a>
          </Link>
        </div>
      </div>
    );
  };

  const renderModalFaqs = () =>
    modalFaqs?.map((faq, index) => {
      const { question, answer } = faq;

      return (
        <div
          key={index}
          className="flex flex-col items-center mb-24 last:mb-0 xl:mb-32">
          <div className="w-full">
            <div className={style['modal-faq__primary']}>
              <Text text={question} />
            </div>
            <div className={style['modal-faq__secondary']}>
              <Text text={answer} />
            </div>
          </div>
        </div>
      );
    });

  const renderModalFaqsCta = () => (
    <div className={style['modal-faq__cta']}>
      <Button
        displayType="primary"
        onClick={() => setIsExtraInfoModalOpen(true)}>
        {modalFaqsCtaText}
      </Button>
    </div>
  );

  //to expand the first faq item
  useEffect(() => {
    if (expandFirstItem) setSelectedFaqIdx(0);
  }, [expandFirstItem]);

  return (
    <div
      id="faqSection"
      className={`c-FaqAccordian font-poppins px-24 py-64 xl:px-[100px] xl:py-64 flex justify-center ${
        withoutBg ? '' : 'bg-light-f2'
      } ${customClassNames}`}>
      <div className="flex justify-center max-w-[924px] xl:w-[924px] self-center items-stretch flex-col">
        {title && !hideTitle ? (
          <div className="mb-32 font-semibold text-center c-FaqAccordian__title text-28 text-dark-33">
            {title}
          </div>
        ) : null}
        <div className="c-FaqAccordian__content">
          {faqs.map((faq, key) => {
            const isLast = key === faqs.length;

            return (
              <div key={key}>
                <div
                  role="button"
                  tabIndex="-1"
                  className="flex flex-row items-center justify-between mb-24 cursor-pointer"
                  onClick={() => toggleFaq(key)}>
                  <div className="font-semibold text-left text-16 text-dark-33 md:text-18">
                    {faq.title}
                  </div>

                  <div className="p-18">
                    {selectedFaqIdx === key ? (
                      <CaretUpIcon />
                    ) : (
                      <CaretDownIcon />
                    )}
                  </div>
                </div>
                <div
                  className={`text-16 mb-28 transition-all duration-500 ease-in-out text-neutral-10 md:text-18 ${
                    (selectedFaqIdx !== key && 'hidden') || ''
                  }`}>
                  <Text text={faq.content} />
                </div>
                {!isLast && (
                  <hr className="h-px mb-24 border-none bg-section-hr" />
                )}
              </div>
            );
          })}
        </div>
        {renderCta()}
        {showModalFaqsCta && renderModalFaqsCta()}
      </div>
      {showModalFaqsCta && (
        <Modal
          open={isExtraInfoModalOpen}
          onClose={() => setIsExtraInfoModalOpen(false)}
          customContainerClass={`${style['extra-info-modal']} font-poppins`}>
          <Text
            text={modalFaqsTitle}
            customClassName="text-dark-33 text-20 font-semibold mb-32 xl:mb-40 xl:text-32"
          />
          {renderModalFaqs()}
        </Modal>
      )}
    </div>
  );
};

FaqAccordian.defaultProps = {
  faqs: [],
  ctaProps: null,
  modalFaqs: modalFaqs,
  modalFaqsTitle: 'Frequently Asked Questions',
  showModalFaqsCta: false,
  modalFaqsCtaText: 'See more',
  withoutBg: false,
  expandFirstItem: false
};

export default FaqAccordian;
