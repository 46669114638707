import { PRODUCT_DEMO_CTA_CLICK_EVENT } from '@/utility/analyticsConsts';
import RoundedButton from '../RoundedButton';
import { trackGAEvent } from '@/utility/analytics';
import { useRouter } from 'next/router';

const RoundedBackgroundCtaSection = ({
  title,
  ctaText,
  disabledCtaText = 'Already Enrolled',
  ctaHandler,
  ctaDisabled,
  customContainerClasses,
  buttonDisplayType = 'primary',
  description,
  secondaryCta,
  bgColor
}) => {
  const router = useRouter();

  return (
    <div className="mx-auto px-24 py-40 md:px-40 xl:px-0 xl:max-w-[1120px]">
      <div
        className={`w-full flex flex-col items-center py-[120px] px-32 duration-300 hover:scale-105 hover:shadow-md transition ease-in-out delay-150 md:px-80 md:py-80 rounded-[400px] ${customContainerClasses}`}
        style={{ backgroundColor: bgColor ? bgColor : '#EFEFFF' }}>
        <h2
          className="font-semibold text-center whitespace-pre-line text-32 text-neutral-10 md:text-40"
          data-testid="homepage-rounded-section-title">
          {title}
        </h2>
        {description && (
          <h2 className="mt-16 font-medium text-center whitespace-pre-line text-body-md text-npl-text-icon-on-light-surface-secondary">
            {description}
          </h2>
        )}
        <div className="mt-[48px] flex space-x-12">
          <RoundedButton
            displayType={buttonDisplayType}
            disabled={ctaDisabled}
            onClick={ctaHandler}
            customClassNames="!h-[56px] !font-medium !text-button-lg text-[#1b1b1b]"
            data-testid="homepage-rounded-section-start-button">
            {ctaDisabled ? disabledCtaText : ctaText}
          </RoundedButton>
          {secondaryCta && !secondaryCta?.isHidden && (
            <RoundedButton
              displayType="outline"
              onClick={() => {
                trackGAEvent(PRODUCT_DEMO_CTA_CLICK_EVENT, {
                  sectionId: 'RoundedBackgroundCtaSection'
                });
                router.push(secondaryCta?.url);
              }}
              customClassNames="!h-[56px] !font-medium !text-button-lg text-[#1b1b1b]"
              data-testid="homepage-rounded-section-start-button">
              {secondaryCta?.ctaText}
            </RoundedButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoundedBackgroundCtaSection;
