import IconByName from '@/components/common/IconsV2/IconByName';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { useRouter } from 'next/router';
import React from 'react';

const TestimonialV2 = ({
  title,
  description,
  cardList,
  reverseIcons,
  connectorIcon,
  cta
}) => {
  const router = useRouter();

  return (
    <div className="py-64 px-24 md:py-[80px] md:px-[40px]">
      <h2
        className="text-heading-xl text-[#1b1b1b] font-semibold mb-16 md:text-display-sm md:text-center"
        data-testid="homepage-testimonial-v2-title">
        {title}
      </h2>
      <p className="text-body-md text-[#1b1b1b] opacity-65 mb-32 md:text-body-lg md:mb-40 md:text-center">
        {description}
      </p>
      <div className="space-y-24 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-24 lg:auto-rows-fr lg:max-w-[1040px] lg:mx-auto">
        {cardList.map((card, index) => {
          const {
            profile,
            testimonial,
            url,
            urlText,
            socialIcon,
            socialIconWidth,
            socialIconHeight
          } = card;

          return (
            <div
              className="rounded-[28px] bg-npl-neutral-light-2 overflow-hidden flex flex-col justify-between"
              key={index}>
              <div className="p-16">
                <div className="flex items-center mb-16 space-x-8">
                  <div className="w-[28px] h-[28px] rounded-full overflow-hidden">
                    <NextImage {...profile.imageData} />
                  </div>
                  <p className="text-body-sm font-medium text-[#1b1b1b] opacity-65">
                    {profile.name}
                  </p>
                </div>
                <p className="text-body-md text-[#1b1b1b]">
                  {testimonial}
                </p>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="flex items-center justify-between p-12 px-16 bg-npl-neutral-light-3"
                onClick={() => router.push(url)}>
                <div>
                  <div className="flex items-center mb-8 space-x-4">
                    {reverseIcons ? (
                      <>
                        <IconByName name="nas-io-dark" />
                        <IconByName
                          name={
                            connectorIcon ? connectorIcon : 'arrow-forward'
                          }
                          width={12}
                          height={12}
                        />
                        <IconByName
                          name={socialIcon}
                          width={socialIconWidth || '20'}
                          height={socialIconHeight || '20'}
                        />
                      </>
                    ) : (
                      <>
                        <IconByName
                          name={socialIcon}
                          width={socialIconWidth || '24'}
                          height={socialIconHeight || '24'}
                        />
                        <IconByName
                          name={
                            connectorIcon ? connectorIcon : 'arrow-forward'
                          }
                          width={12}
                          height={12}
                        />
                        <IconByName name="nas-io-dark" />
                      </>
                    )}
                  </div>
                  <p className="text-label-md font-medium text-[#1b1b1b] opacity-65">
                    {urlText}
                  </p>
                </div>
                <div className="-rotate-90">
                  <IconByName
                    name="chevron-down"
                    fill="rgba(27, 27, 24, 0.65)"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {cta && (
        <div className="flex justify-center mt-40">
          <RoundedButton
            displayType={cta?.type}
            onClick={() => {
              router.push(cta?.url);
            }}
            customClassNames="!h-[56px] !font-medium !text-button-lg w-full lg:w-auto"
            data-testid="homepage-community-categories-section-start-button">
            {cta?.text}
          </RoundedButton>
        </div>
      )}
    </div>
  );
};

export default TestimonialV2;
