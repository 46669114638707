import { NextImagePropTypes } from '@/components/common/NextImage';
import PropTypes from 'prop-types';
import React from 'react';
import dynamic from 'next/dynamic';
const ShakaPlayer = dynamic(
  () => import('@/components/common/AdaptiveVideo/partials/ShakaPlayer'),
  {
    ssr: false
  }
);

/* this component is just a wrapper for dynamically importing ShakaPlayer 
cause ShakaPlayer does not support ssr out of the box */
const AdaptiveVideo = (props) => {
  return <ShakaPlayer {...props} />;
};

const ShakaVideoAssetDataType = PropTypes.shape({
  dash: PropTypes.string,
  hls: PropTypes.string,
  mp4: PropTypes.string
});

export const AdaptiveVideoPropTypes = {
  muted: PropTypes.bool,
  onVideoEnd: PropTypes.func,
  videoAssets: ShakaVideoAssetDataType,
  mobileVideoAssets: ShakaVideoAssetDataType,
  thumbnail: PropTypes.shape(NextImagePropTypes),
  checkIsInView: PropTypes.bool,
  isTrailerPlaying: PropTypes.bool
};

export default AdaptiveVideo;
